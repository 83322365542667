<template lang="pug">
  div
    v-dialog(v-model='dialog', width='900')
      template(v-slot:activator='{ on, attrs }')
        tooltip(text='Копировать')
          v-btn.ml-4(fab, x-small, v-bind='attrs', v-on='on', outlined, color='primary')
            v-icon
              | mdi-content-copy
      v-card(v-model='dialog')
        v-card-title.headline.grey.lighten-2
          div Копировать "{{ name }}"
        v-card-text.mt-5
          v-text-field(label='Придумайте название', v-model='newName', dense, outlined, hide-details)
          .mt-5(style='text-align: right')
            v-btn(@click='copy', elevation='0', color='accent')
              | Копировать
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Tooltip from '@/components/tooltip';
import loading_mixin from '@/mixins/loading_mixin';
import {CREATE_INVENTORY, GET_INVENTORY_BY_ID} from '@/store/const/inventory';

export default {
  name: 'Copy',
  components: {Tooltip},
  mixins: [loading_mixin],
  props: {
    name: {
      type: String,
    },
    unitIDs: {
      type: Array,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      newName: '',
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('Inventory', ['getInventory'])
  },
  methods: {
    ...mapActions('Inventory', [GET_INVENTORY_BY_ID, CREATE_INVENTORY]),
    async copy() {
      if (!this.validation()) return;
      await this.GET_INVENTORY_BY_ID({id: this.id})
      if (this.isError(GET_INVENTORY_BY_ID)) {
        this.errorShow(this.errorMessage(GET_INVENTORY_BY_ID));
        return;
      }
      let data = {
        name: this.newName,
        surfaceIDs: this.getInventory.surfaceIDs,
      };
      await this.CREATE_INVENTORY(data)
      if (this.isError(CREATE_INVENTORY)) {
        this.errorShow(this.errorMessage(CREATE_INVENTORY));
        return;
      }
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: 'Инвентарь успешно скопирован',
      });
      this.$emit('update');
      this.dialog = false;
    },
    validation() {
      if (!this.name) {
        this.errorShow('Пустое название инвентаря');
        return false;
      }
      if (this.name.length < 4) {
        this.errorShow('Название должно быть более 3 знаков');
        return false;
      }
      return true;
    },
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
  },
};
</script>

<style scoped></style>
