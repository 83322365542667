<template lang="pug">
	v-container(fluid)
		v-row.mb-4(justify="end")
			.center-block.mr-3
				v-text-field(label='Поиск', v-model='search', dense, outlined, hide-details)
				v-btn.ml-4(elevation='0', color='accent') Загрузить список (csv)
				v-btn.ml-4(@click='$router.push("/radar/inventory/create/")', elevation='0', color='accent') Новый список
		v-data-table.v-data-table--custome(
			:headers='headers',
			:loading='isLoading',
			loading-text='Данные загружаются, ожидайте',
			:items='getItems',
			:hide-default-footer='getItems.length < 11',
		)
			template(v-slot:no-data)
				div(
					style='color: #000; border-bottom: 1px solid #dce6eb; padding: 20px 0 25px; background: #fafbfc; margin: 0 -16px'
				)
					div(style='color: #666666; font-size: 20px') Вы ещё не создали ни одного списка
			template(v-slot:item.name='{ item }')
				v-btn.v-btn--table-link(
					@click='$router.push("/radar/inventory/" + item.id)',
					outlined,
					small,
					color='primary'
				) {{ item.name }}
			template(v-slot:item.actions='{ item }')
				div(style='display: flex; align-items: center')
					tooltip(text='Редактировать')
						v-btn(
							@click='$router.push("/radar/inventory/" + item.id)',
							fab,
							x-small,
							outlined,
							color='primary'
						)
							v-icon(small) mdi-lead-pencil
					popup-copy(v-bind='item', @update='update')
					popup-delete(v-bind='item', @update='update')
</template>

<script>
import Tooltip from '@/components/tooltip';
import PopupCopy from '@/components/popup/inventory/copy';
import PopupDelete from '@/components/popup/inventory/delete';
import {mapActions, mapGetters} from 'vuex';
import {GET_INVENTORY_LIST} from '@/store/const/inventory';
import loading from '@/mixins/loading_mixin';

export default {
	name: 'Inventory',
	components: {Tooltip, PopupCopy, PopupDelete},
	mixins: [loading],
	data() {
		return {
			search: '',
			headers: [
				{
					text: 'Название',
					value: 'name',
					sortable: false,
				},
				{
					text: 'Дата создания',
					value: 'created',
					width: '20%',
					align: 'center',
					sortable: false,
				},
				{
					text: 'Количество элементов',
					value: 'size',
					width: '20%',
					align: 'center',
					sortable: false,
				},
				{
					value: 'actions',
					width: 160,
					sortable: false,
				},
			],
		};
	},
	computed: {
		...mapGetters('Inventory', ['getItems', 'getInventoryList']),
	},
	mounted() {
		this.update();
	},
	methods: {
		...mapActions('Inventory', [GET_INVENTORY_LIST]),
		async update() {
			await this.GET_INVENTORY_LIST();
			if (this.isError(GET_INVENTORY_LIST)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(GET_INVENTORY_LIST) ? this.errorMessage(GET_INVENTORY_LIST) : 'Не удалось получить инвентарь'
				})
			}
		},
	},
};
</script>

<style scoped></style>
